import { PlayButton, Player } from "./VideoPlayer.styles"

import { BsPlayFill } from 'react-icons/bs'
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player/youtube'

//! Currently the module only accepts YT links to reduce bundle size, remove /youtube to accept other links



const VideoPlayer = (props) => (
  <Player style={{ ...props.style }} >
    <ReactPlayer
      url={props.url} 
      height="100%"
      width="100%"
      light 
      playIcon={<PlayIcon/>} 
      style={{ borderRadius: '10px', maxWidth:"100%", minHeight:"200px", ...props.playerStyle }}/>
  </Player>
);


const PlayIcon = () => (
  <PlayButton>
    <BsPlayFill/>
  </PlayButton>
)

VideoPlayer.propTypes = {
  style: PropTypes.shape(),
  playerStyle: PropTypes.shape(),
  url: PropTypes.string,
};

VideoPlayer.defaultProps = {
  style: null,
  playerStyle: null,
  url: "https://www.youtube.com/watch?v=NpEaa2P7qZI",
};

export default VideoPlayer;
