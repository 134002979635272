import PropTypes from "prop-types";
import FaqAccordionSection from "../../sections/FaqAccordionSection";
import React from "react";

const CustomResourceFAQ = (props) => (
    <section itemProp="articleBody" className="article-body">
        <FaqAccordionSection faqs={props.faqs} backgroundColor="#ffffff"/>
    </section>
);

CustomResourceFAQ.propTypes = {
    faqs: PropTypes.array
};

CustomResourceFAQ.defaultProps = {
    faqs: []
};

export default CustomResourceFAQ;