// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"
import React, {useEffect, useState} from "react"
import CtaSection from "../components/sections/CtaSection"
import Layout from "../components/global/Layout"
import LearningCenterHeader from "../components/page-specific/Learning Center/LearningCenterHeader/LearningCenterHeader"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import { queries } from "../breakpoints"
import styled from 'styled-components';
import { buildTopicHierarchy } from "./resourcesHelperFunctions";
import resources from "./resources";
import FaqAccordionSection from "../components/sections/FaqAccordionSection/FaqAccordionSection";
import CustomResourceFAQ from "../components/global/CustomResourceFAQ/CustomResourceFAQ";
import VideoPlayer from "../components/global/VideoPlayer/VideoPlayer";

const defaultBreadcrumbValue = {
  link: "/learning-center/",
  text: "Home",
  active: false
};

const ResourcePostTemplate = ({
  data: { resource, allTopics },
}) => {

  const topicHierarchy = buildTopicHierarchy(allTopics.nodes);
  const [breadcrumbs, setBreadcrumbs] = React.useState([defaultBreadcrumbValue]);
  const [activeAncestor, setActiveAncestor] = React.useState();

  useEffect(() => {
    let seoResourceRawData = resource.seo.schema.raw.replaceAll('item":"', 'item":"https://corporate.grubhub.com');
    resource.seo.schema.raw = seoResourceRawData;
  }, []);

  let breadcrumbsArray = [];
  useEffect( () =>{
    if(resource && resource.topics.nodes.length > 0 && !activeAncestor ){
      resource.topicsNames = [];
      resource.topicsParentId = [];
      resource.topics.nodes.forEach( ( topicInResource ) => {
        resource.topicsNames.push( topicInResource.name );
        resource.topicsParentId.push( topicInResource.parentId );
      } );

      breadcrumbsArray = buildBreadcrumbsFromTopic( topicHierarchy, breadcrumbsArray, 0 );

      breadcrumbsArray.push({
        link: ``,
        text: `${parse( resource.title )}`,
        active: true
      })

      setBreadcrumbs( [ ...breadcrumbs, ...breadcrumbsArray ] );
    }
  }, [])

  const buildBreadcrumbsFromTopic = ( topicsList, breadcrumbsArray, level, linkLevels ) => {
    topicsList.forEach( ( topic ) => {

      if( linkLevels === undefined ){
        linkLevels = getLinkURL( topic, level, [] );
      }

      if( resource.topicsNames.includes( topic.name ) && resource.topicsParentId.includes( topic.parentId ) ) {
        linkLevels = getLinkURL( topic, level, linkLevels );
        breadcrumbsArray.push( {
          link: (level === 0 ? linkLevels[level] : linkLevels[level+1]),
          text: `${topic.name}`,
          active: false
        } );
      }
      if( topic.subTopics.length > 0 ) {
        linkLevels = ( level === 0 ) ? getLinkURL(topic, level + 1, linkLevels ) : linkLevels;
        breadcrumbsArray = buildBreadcrumbsFromTopic( topic.subTopics, breadcrumbsArray, level + 1, linkLevels );
      }
      if( level === 0 && breadcrumbsArray.length > 0 && !resource.topicsNames.includes( topic.name ) ) {
        level++;
        breadcrumbsArray = [
          {
            link: "/learning-center#-" + topic.name,
            text: `${topic.name}`,
            active: false
          },
          ...breadcrumbsArray
        ]
      }

    } );

    return breadcrumbsArray;
  }

  const getLinkURL = ( topic, level, linkLevels ) => {
    if( level === 0 ) {
      linkLevels[0] = "/learning-center#-" + topic.name.replace(' ', '-').toLowerCase();
      linkLevels[1] = "/resources/?resource_parent=" + topic.name;
    }
    if( level === 1 ) {
      linkLevels[2] = linkLevels[1] + '&resource_type=' + topic.name;
    }
    if( level === 2 ) {
      linkLevels[3] = linkLevels[1] + '&resource_type=' + topic.name
    }

    return linkLevels;
  }


  const switchMasterTopics = (masterTopic) => {
    window.location.href = `/resources/?resource_type=${masterTopic}`
  }


  // Define FAQ properties used in the accordion component for the following resources having the id:
  // ( page title: 'Self-Service Account resources' )
  // ( page title: 'Pre-signup resources' )
  const FAQ_SELF_SERVICE_ACCOUNT = [
    {
      header: "How do I access my Grubhub Corporate Account?",
      description: "<p>To access your account anytime, visit <a href=\"http://www.grubhub.com\">www.grubhub.com</a> and sign in. Click on your name in the top-right corner and click “Business account” (the briefcase icon).</p>",
    },
    {
      header: "What should I do if I’m having issues with my credit card?",
      description: "If you’re unable to place an order or running into problems processing payments, please make sure to notify your bank and let them know that your credit card will experience higher than usual activity. If transactions are blocked by your bank, you or the people on your account can’t place orders.",
    },
    {
      header: "How do I update the credit card for my line of credit?",
      description: "To update the credit card you want to use for the line of credit, log in to your account and click on the “Your account” tab in the upper-right navigation bar. Then, click “Update payment.”",
    },
    {
      header: "How do I add people to my Self-Service Corporate Account? ",
      description: "<p><a href=\"http://www.grubhub.com/corporate/signup?\">Log in</a> to your account and click “People” in the upper-right navigation bar. There, you’ll be able to see a list of active users on your account. You can also search by name.</p>" +
          "<p>To add people to your account, simply click the “Add people” tab. If you would like to change a person’s email address, you must delete the person from your account and re-add them with the preferred email address.</p>" +
          "<p>Once added to your account, you have the option to create a Group and add individual people to them – this will help you organize people in your Grubhub Corporate account, especially if there are a large number of people (e.g. weekly lunch, birthday party, marketing department). This method offers a quicker way to assign groups of people to a Lines of Credit instead of individually. Here’s how:</p>" +
          "<ol>\n" +
          "<li>After logging in to your account, click “People” in the upper-right navigation bar.</li>\n" +
          "<li>Toggle from “All people” to “Groups” above the search bar and click “Add group”.</li>\n" +
          "<li>Fill in your Group information then click “Add”.</li>\n" +
          "<li>Finally, click “Add people” to assign those you want to be included in your group. Now you can assign Groups to a Line of Credit instead of individually adding people for ease.</li>\n" +
          "</ol>",
    },
    {
      header: "How will people know that they’ve been added to my account? ",
      description: "<p>Once you add people to an account, they’ll receive an automated Grubhub <a href=\"https://corporate.grubhub.com/resource/what-to-expect-throughout-the-standard-corporate-account-signup-process/#activation_email\">email</a> letting them know that they have been added to your account. This email also includes instructions on how they can activate their accounts using the Grubhub username you created for them.</p>" +
          "<p>However, this email does not contain information about when the line of credit starts and how much people have available to use. We recommend that you provide this information directly to the people on your account.</p>" +
          "<p>The email will be sent to the email address you uploaded for each person and will be sent from <a href=\"mailto:corporatecare@grubhub.com\">corporatecare@grubhub.com</a>.</p>" +
          "<p><img decoding=\"async\" loading=\"lazy\" class=\"alignnone wp-image-2443\" src=\"https://corporate-wp-stage.grubhub.com/wp-content/uploads/2019/04/10.1-Welcome-Email.jpg\" alt=\"\" width=\"917\" height=\"610\"></p>" +
          "sample welcome email",
    },
    {
      header: "How do I create a new line of credit?",
      description: "<p>To create a new line of credit, <a href=\"http://www.grubhub.com/corporate/signup?\">log into your account</a> and click on “Line of credit” in the upper-right navigation bar. Then, click on “Create a line of credit.” You’ll be able to set your desired credit amount, the duration of the line of credit (the amount of time a person has to spend the money given to them) and if the budget is one-time or recurring. You can make edits to your line of credit at any time.</p>" +
          "<p><a href=\"https://corporate.grubhub.com/resource/training-videos-self-service-corporate-account/#add_loc\">View our video tutorial</a> for a step-by-step guide on how to create a new line of credit.</p>",
    },
    {
      header: "How do I add the people on my account to my line of credit?",
      description: "<p>Log into your account and click on “Line of credit” in the upper-right navigation bar. Then, click on the “Active” tab to view your active lines of credit. Choose your desired line of credit and hover over the “View details” arrow. To select people on your account to be added to your line of credit, simply click the “Add people” tab.</p>" +
          "<p>Once you’ve added people to your line of credit, you’ll be able to see all users added to use this line of credit. (<a href=\"https://corporate.grubhub.com/resource/training-videos-self-service-corporate-account/#add_people\">Check out our video tutorial</a> for a step-by-step guide on how to add people to your line of credit.)</p>" +
          "<p>If you sort people in your Grubhub Corporate account using Groups, you can toggle to “Groups” in the same line of credit details page to select groups on your account to be added to your line of credit. Simply click the “Assign group” tab. <strong>Please note</strong>:</p>" +
          "<ul>\n" +
          "<li>People removed from a Group will also be removed from any active Lines of Credit associated with that group. The opposite also applies, people added to a Group will be added to any active Lines of Credit currently associated with that Group.</li>\n" +
          "<li>An individual person <strong>can not</strong> be added twice to a Line of Credit, either individually and/or via a Group. (E.g. if a person is added to a Group that is assigned to a Line of Credit, there will be no option to individually add that person to the same Line of Credit afterwards)</li>\n" +
          "</ul>",
    },
    {
      header: "How can people view and utilize their line of credit?",
      description: "<p>If people are using the web to visit grubhub.com and place an order, they’ll see their credit amount in the restaurant search area of the homepage and at their checkout page. If people are using the Grubhub app, they can view and apply the credit extended to them on their order checkout page. (<a href=\"https://corporate.grubhub.com/resource/training-videos-self-service-corporate-account/#view_loc\">Here’s a video tutorial</a> on how people can view and utilize their line of credit.)</p>" +
          "<p><img decoding=\"async\" loading=\"lazy\" class=\"alignnone wp-image-2435\" src=\"https://corporate-wp-stage.grubhub.com/wp-content/uploads/2019/06/5.3-apply-loc-at-checkout.jpg\" alt=\"\" width=\"1021\" height=\"490\"></p>" +
          "<p>If people don’t see their line of credit on this page, it could be due to one of the following reasons:</p>" +
          "<ol>\n" +
          "<li aria-level=\"1\">They are logged into <a href=\"http://www.grubhub.com\">www.grubhub.com</a> under a username that’s different from the one you used when adding them to the line of credit.</li>\n" +
          "<li aria-level=\"1\">Their credit amount has been spent, or their line of credit window has not begun or is finished.</li>\n" +
          "</ol>",
    },
    {
      header: "Can I order a group order with Self-Service Accounts?",
      description: "<p>Yes. To start a group order, please go to the desired restaurant page via the Grubhub website or the Grubhub app. Once you’ve found a restaurant that you’d like to order from, click on “Start group order.” (Please note that not all restaurants support group orders.)</p>",
    },
    {
      header: "Can people order alcohol through my account?",
      description: "<p>There are no restrictions on what can be ordered using the line of credit for Self-Service Accounts. If you’re interested in restricting alcohol, <a href=\"https://corporate.grubhub.com/contact-us\">get in touch</a> with a Grubhub Sales agent through and learn about an Enterprise account.</p>",
    },
    {
      header: "How do I deactivate a line of credit?",
      description: "<p>Go to your admin account and click on the “Line of credit” tab in the navigation bar. Locate the line of credit you want to deactivate in the “Active” tab and click on the trash can icon next to the line of credit.</p>" +
          "<p>Once you deactivate a line of credit, the people on that line of credit can no longer place orders. To ensure that no more charges appear on your credit card, remove the credit card on file by clicking “Update payment” in your admin account.</p>",
    },
    {
      header: "I need more help with my Self-Service Account. Who do I contact?",
      description: "<p>If you have additional questions, please contact Corporate Care at corporatecare@grubhub.com or call (844) 478-2249, Option 2.</p>",
    },
    {
      header: "How do I close my account?",
      description: "<p>We’re sorry to see you go. If you wish to close your account,simply remove people and delete any active meal credits.\n" +
          "\n</p>" +
          "<p>To do this, log in to your Grubhub account. In the upper right hand corner, navigate to “Business account” to land in your admin portal. Navigate to the People tab and click “remove” until only your name and email remain on the account. Next click on the “Line of credit” tab and click the trash bin icon to delete existing meal credits.</p>",
    }
  ];

  const FAQ_PRE_SIGNUP = [
    {
      header: "What is a Self-Service Corporate Account? ",
      description: "<p><span>A Self-Service Corporate Account is a fast way for anyone to create a budget and feed employees, clients and others same-day! Signup is free and only takes a few minutes!&nbsp;</span></p>",
    },
    {
      header: "How do I know if a Self-Service Account is right for me?",
      description: "<p><span>A Self-Service Account is intended for anyone looking to feed employees or provide meals for client or board meetings, occasional happy hours and webinars.</span></p>" +
          "<p><span>As an account admin, you’ll gain access to a management portal where you can add/remove people from your account, create/delete lines of credit to fund food budgets, manage your credit card and see a history of orders.</span></p>" +
          "<p><span>For teams that are building flexible meal programs or are ordering daily or weekly, we recommend our Enterprise solution.&nbsp;</span></p>",
    },
    {
      header: "Who can create a Self-Service Account?",
      description: "<p><span>Whether you’re a founder at a non-profit looking to show appreciation to your employees, or an executive assistant ordering for an important client meeting, anyone can create a Self-Service Grubhub corporate account and become an admin.&nbsp;</span></p>" +
          "<p><span>As an admin, you’ll gain access to a management portal where you can add/remove people from your account, create/delete lines of credit to fund food budgets, manage your credit card, see a history of orders and access detailed receipts.&nbsp;</span></p>",
    },
    {
      header: "What do I need in order to sign up for a Self-Service Account?",
      description: "<p><span>To get started with a Self-Service Account, all you’ll need is a credit card with a max limit that can cover the amount of money you want to allocate and the 6% transaction fee. For example, if you are giving 100 people $20 to spend on lunch, make sure your credit card has a max credit limit of at least $2,500 to act as a buffer for the 6% transaction fee.</span></p>" +
          "<p><span>The signup process consists of 5 easy steps and you can start feeding people as soon as you finish setting up your account!</span></p>",
    }
  ];

  const shouldUseCustomFAQ = resource.useCustomFaqResources.useCustomFaq;
  const customFaqId = resource.useCustomFaqResources.customFaqId;

  const shouldUseVideo = resource.resourcesVideo.useVideo;
  const videoUrl = resource.resourcesVideo.videoUrl;

  // Return custom FAQ value
  const [accordionFAQs, setAccordionFAQs] = useState(() => {
    if( customFaqId === 'Self-Service Account' ){ // page title is 'Self-Service Account resources'
      return FAQ_SELF_SERVICE_ACCOUNT;
    }
    else if ( customFaqId === 'Pre-signup' ){ // page title is 'Pre-signup resources'
      return FAQ_PRE_SIGNUP;
    }
    return [];
  });
  return (
      <Layout
          title={parse(resource.title)}
          description={resource.seo.metaDesc}
          noindex={resource.seo.metaRobotsNoindex === "noindex"}
      nofollow={resource.seo.metaRobotsNofollow === "nofollow"}
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "learn more",
        pageName: `resource - ${resource.title}`,
        brand: "grubhub"
      }}
    >
      {/* Seo component provided by gatsby-plugin-wpgraphql-seo */}
      <Seo post={resource} />
      <LearningCenterHeader
        breadcrumbs={breadcrumbs || [defaultBreadcrumbValue]}
        allTopics={allTopics}
        activeAncestor={activeAncestor}
        switchMasterTopics={(masterTopic) => switchMasterTopics(masterTopic)}
      />
      <ArticleResourceSingle>
        <article
            itemScope
            itemType="http://schema.org/Article"
            className="corporate-learning-center-post container"
        >
          <Headline itemProp="headline">{parse(resource.title)}</Headline>
          {
            shouldUseVideo ? 
              (<VideoContainer>
                <video style={{
                  width: '100%',
                  borderRadius: '8px'
                }} controls>
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </VideoContainer>) : null
          }
          {!shouldUseCustomFAQ && !!resource.content  && (
            <section itemProp="articleBody" className=" article-body">
              {parse(resource.content)}
            </section>
          )}

          { /* show custom FAQ accordion   */
              shouldUseCustomFAQ && ( <CustomResourceFAQ faqs={accordionFAQs} /> )
          }

          {resource.modified && <LastUpdated>Last updated on {resource.modified}</LastUpdated>}
        </article>
      </ArticleResourceSingle>
      <CtaSection
          headline="Still have questions?"
          to="/get-started/"
          backgroundColor="#F9F9F9"
      />
    </Layout>
  )
}

export default ResourcePostTemplate

export const pageQuery = graphql`
  query ResourcePostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current resource by id
    allTopics: allWpTopic {
      nodes {
        id
        name
        description
        parentId

      }
    }
    resource: wpResource(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      modified(formatString: "MMMM DD, YYYY")
      uri
      categories {
        nodes {
          slug
        }
      }      
      useCustomFaqResources {
        useCustomFaq
        customFaqId
      }
      resourcesVideo {
        useVideo
        videoUrl
      }
      topics {
        nodes {
          name
          termTaxonomyId
          parentId
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`

const Headline = styled.h1`
  color: var(--color-text);
  font-size: var(--fontSize-8);
  font-weight: bold;
  margin-bottom: 2rem;
  @media(${queries.max_break_sm}){
    font-size: var(--fontSize-4);
  }
`

const LastUpdated = styled.p`
  color: #AEAEAE;
  font-size: var(--fontSize-0);
  margin: 2rem 0;
`

const ArticleResourceSingle = styled.div`
  .resource__single_cta {
    display: inline;
    padding: 12px 30px;
    border-radius: 100px;
    font-weight: bold;
    font-size: var(--fontSize-1);
    min-width: 175px;
    display: inline-block;
    text-align: center;
    background-color: var(--color-primary);
    color: white;
    font-family: var(--font-body);
    border: none;
    transition: all .1s;
    &:hover {
        color: white;
        text-decoration: none;
        background-color: var(--color-primary-dark);
    }
    &:active{
        background-color: var(--color-primary-darker);
    }
  }
  .MuiAccordionDetails-root{
    flex-direction: column;
  }
`

const VideoContainer = styled.div`
  width: 100%;
  max-width: 420px;
  display: flex;
  justify-content: center;
  padding: 0px 10px 0px 10px;
`