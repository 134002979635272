import styled from 'styled-components';

const BORDER_RADIUS = '6px'

export const Player = styled.div`
    border-radius: 10px;
    aspect-ratio: 16 / 9; 
    max-width: 640px;
    min-width: 300px;
    margin: 0 auto;
    iframe, .react-player__preview {
        border-radius: ${BORDER_RADIUS};
    }
`;


export const PlayButton = styled.div`
    background-color: white;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin: 75px 0;
    svg {
        width: 40px;
        height: 40px;
        color: var(--color-primary);
    }
`;